
import Vue from 'vue'
import Color from 'color'

export default Vue.extend({
  props: {
    outlined: { type: Boolean, default: false },
    width: { type: String, default: 'auto' },
    color: { type: String, default: '#8700FF' }
  },
  methods: {
    buttonMouseover (e: MouseEvent) {
      const el = e.target as HTMLElement

      if (el) el.style.backgroundColor = Color(this.color).fade(this.outlined ? 0.94 : 0.1)
    },
    buttonMouseleave (e: MouseEvent) {
      const el = e.target as HTMLElement

      if (el) el.style.backgroundColor = this.outlined ? 'transparent' : this.color
    }
  },
  computed: {
    buttonStyles (): string {
      return `
        width: ${this.width}px;
        border: 1px solid ${this.color};
        background-color: ${this.outlined ? 'transparent' : this.color};
        color: ${this.outlined ? this.color : '#FFF'};
      `
    }
  }
})
