
import Vue from 'vue'
import { Status } from '@/types'

export default Vue.extend({
  computed: {
    status (): Status {
      return this.$store.state.status
    },
    message (): string {
      if (this.status === 'CONTACT_IN_QUARANTINE') {
        return 'Essa pesquisa já foi respondida por você. Obrigado :)'
      }

      return ''
    }
  }
})
