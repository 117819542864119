import Vue from 'vue'
import Vuex, { ActionTree } from 'vuex'
import axios, { AxiosError } from 'axios'
import { intersection } from 'lodash'
import {
  State,
  Survey,
  Contact,
  Widget,
  Question,
  QuestionSequence,
  Answer,
  AnswerPayload,
  ThanksMessageEngine,
  Status
} from '@/types'

Vue.use(Vuex)

const api = axios.create({ baseURL: 'https://survey-external-api.ms.harmo.me/v1' })

const defaultState = (): State => {
  return {
    widgetUUID: null,
    distributionContactUUID: null,
    widgetElement: null,
    contact: null,
    isPreview: false,
    survey: null,
    widget: null,
    questions: [],
    questionSequences: [],
    questionsWithError: [],
    answers: [],
    step: 0,
    availableThanksMessageEngine: [],
    status: 'NOT_OPENED',
    loading: {
      getData: false
    },
    color: {
      primary: '#FFF',
      red: '#EE3C37',
      error: '#EE3C37',
      black: '#181818',
      grey: '#9E9E9E',
      google: '#4285F4',
      ifood: '#EA1D2C',
      tripadvisor: '#34E0A1'
    }
  }
}

const state = defaultState()

const getters = {
  questions: (state: State) => {
    const questions = []

    const baseQuestionSequence = state.questionSequences.find((questionSequence: QuestionSequence) => !questionSequence.condition)

    if (baseQuestionSequence) {
      for (const questionUUID of baseQuestionSequence.sequence) {
        const question = state.questions.find((question: Question) => question.uuid === questionUUID)

        if (question) questions.push(question)
      }
    }

    for (const [questionIndex, question] of questions.entries()) {
      const questionAnswer = state.answers.find((answer: Answer) => answer.question.uuid === question.uuid)

      if (questionAnswer) {
        const questionSequence = state.questionSequences.find((_questionSequence: QuestionSequence) => _questionSequence.parentQuestionUUID === question.uuid && intersection(_questionSequence.condition, question.type === 'checkbox' ? questionAnswer.answer : [questionAnswer.answer]).length > 0)

        if (questionSequence) {
          for (const [index, questionUUID] of questionSequence.sequence.entries()) {
            const secondLevelQuestion = state.questions.find((_question: Question) => _question.uuid === questionUUID)

            if (secondLevelQuestion) {
              questions.splice(questionIndex + 1 + index, 0, secondLevelQuestion)
            }
          }
        }
      }
    }

    return questions
  }
}

const mutations = {
  RESET (state: State) {
    Object.assign(state, defaultState())
  },
  SET_WIDGET_UUID (state: State, widgetUUID: string) {
    state.widgetUUID = widgetUUID
  },
  SET_DISTRIBUTION_CONTACT_UUID (state: State, distributionContactUUID: string) {
    state.distributionContactUUID = distributionContactUUID
  },
  SET_WIDGET_ELEMENT (state: State, widgetElement: Element) {
    state.widgetElement = widgetElement
  },
  SET_CONTACT (state: State, contact: Contact) {
    state.contact = contact
  },
  SET_IS_PREVIEW (state: State, isPreview: boolean) {
    state.isPreview = isPreview
  },
  SET_SURVEY (state: State, survey: Survey) {
    state.survey = survey
  },
  SET_WIDGET (state: State, widget: Widget) {
    state.widget = widget
  },
  SET_QUESTIONS (state: State, questions: Question[]) {
    state.questions = questions
  },
  SET_QUESTION_SEQUENCES (state: State, questionSequences: QuestionSequence[]) {
    state.questionSequences = questionSequences
  },
  SET_QUESTIONS_WITH_ERROR (state: State, questionsWithError: Question['uuid'][]) {
    state.questionsWithError = questionsWithError
  },
  ADD_ANSWER (state: State, answer: Answer) {
    const answerFoundIndex = state.answers.findIndex(_answer => _answer.question.uuid === answer.question.uuid)

    if (answerFoundIndex >= 0) {
      state.answers[answerFoundIndex] = answer
    } else {
      state.answers.push(answer)
    }
  },
  NEXT_QUESTION (state: State) {
    state.step = state.step + 1
  },
  PREVIOUS_QUESTION (state: State) {
    if (state.step > 0) {
      state.step = state.step - 1
    }
  },
  SET_STATUS (state: State, status: Status) {
    state.status = status
  },
  SET_LOADING (state: State, { key, value }: { key: string; value: boolean }) {
    state.loading[key] = value
  }
}

const actions = {
  async getData ({ state, commit, dispatch }) {
    try {
      commit('SET_LOADING', { key: 'getData', value: true })

      const { data } = await api.post(`/widget/${state.widgetUUID}`, state.contact, {
        params: state.isPreview ? { preview: 'true' } : undefined
      })

      commit('SET_SURVEY', data.survey)
      commit('SET_WIDGET', data.widget)
      commit('SET_QUESTIONS', data.questions)
      commit('SET_QUESTION_SEQUENCES', data.sequences)
      commit('SET_DISTRIBUTION_CONTACT_UUID', data.distributionContactUUID)

      console.log('%c HARMO widget ', 'background: #8700FF; color: #FFF; padding: 2px 0', `Integrado com sucesso! Delay para abertura: ${state.widget?.config.openDelaySeconds}s`)

      if (!state.isPreview) await new Promise(resolve => setTimeout(resolve, Number(state.widget?.config.openDelaySeconds) * 1000))

      commit('SET_STATUS', 'OPENED')
      dispatch('setStatus', { status: 'start' })
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        switch (error.response.data.message) {
          case 'Widget not found': return console.log('%c HARMO widget ', 'background: #EE3C37; color: #FFF; padding: 2px 0', 'Não encontrado. Verifique se o uuid está correto, por favor.')
          case 'Contact is under quarantine.': return console.log('%c HARMO widget ', 'background: #EE3C37; color: #FFF; padding: 2px 0', `${state.contact?.email} está em quarentena.`)
          default: return console.error(`HARMO widget: ${error.response.data.message}`)
        }
      }
    } finally {
      commit('SET_LOADING', { key: 'getData', value: false })
    }
  },
  async verifyQuestionQuarantine ({ state, commit }, { answer }: { answer: Answer }) {
    try {
      const { data } = await api.post(`/survey/${state.survey?.uuid}/quarantine/${answer.question.uuid}/verify`, {
        answer: answer.answer
      })

      if (data.contactInQuarantine) {
        commit('SET_STATUS', 'CONTACT_IN_QUARANTINE')
      }

      return data.contactInQuarantine
    } catch (error) {
      console.error(error)
    }
  },
  async saveAnswer ({ state, getters, commit, dispatch }, { answer }: { answer: Answer }) {
    try {
      commit('SET_LOADING', { key: 'saveAnswer', value: true })

      commit('SET_QUESTIONS_WITH_ERROR', state.questionsWithError.filter((questionUUID: Question['uuid']) => questionUUID !== answer.question.uuid))

      commit('ADD_ANSWER', answer)

      if (state.isPreview) {
        await new Promise(resolve => setTimeout(resolve, 200))
      } else {
        const answerPayload = {
          question: answer.question.uuid,
          answer: Array.isArray(answer.answer) ? answer.answer : [answer.answer],
          distributionContact: state.distributionContactUUID
        } as AnswerPayload

        await api.post('/answer', answerPayload)
      }

      if (answer.question.type !== 'checkbox' && answer.question.type !== 'text') {
        commit('NEXT_QUESTION')
      }

      if (state.step === getters.questions.length) {
        commit('SET_STATUS', 'CONCLUDED')
        dispatch('setStatus', { status: 'conclude' })
      }
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADING', { key: 'saveAnswer', value: false })
    }
  },
  async getAvailableThanksMessageEngine ({ state, commit }) {
    try {
      commit('SET_LOADING', { key: 'getAvailableThanksMessageEngine', value: true })

      const { data } = await api.get(`/survey/distributionContact/${state.distributionContactUUID}/thanks-message/review-available-engine`)

      state.availableThanksMessageEngine = data
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADING', { key: 'getAvailableThanksMessageEngine', value: false })
    }
  },
  async getThanksMessageReviewLink ({ state }, { engineId, credentials }: { engineId: ThanksMessageEngine['engine']; credentials: ThanksMessageEngine['pageId'] }) {
    try {
      const { data } = await api.get(`/survey/distributionContact/${state.distributionContactUUID}/thanks-message/link/${engineId}/${credentials}`)

      return data.link
    } catch (error) {
      console.error(error)
    }
  },
  async setStatus ({ state, commit }, { status }: { status: 'start' | 'conclude' }) {
    try {
      commit('SET_LOADING', { key: status, value: true })

      if (!state.isPreview) {
        await api.post(`/status/${status}/${state.distributionContactUUID}`, null, {
          params: {
            s: state.survey?.uuid
          }
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADING', { key: status, value: false })
    }
  }
} as ActionTree<State, unknown>

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
