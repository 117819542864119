
import Vue from 'vue'
import store from '@/store'
import QuestionComponent from '@harmodeveloper/survey-questions'
import Btn from '@/components/Btn.vue'
import CloseBtn from '@/components/CloseBtn.vue'
import ThanksMessage from '@/components/ThanksMessage.vue'
import StatusMessage from '@/components/StatusMessage.vue'
import { Survey, Widget, Question, Answer, Status } from '@/types/index'
import { AxiosError } from 'axios'

export default Vue.extend({
  store,
  components: {
    Question: QuestionComponent,
    Btn,
    CloseBtn,
    ThanksMessage,
    StatusMessage
  },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  async mounted () {
    try {
      console.log('%c HARMO widget ', 'background: #8700FF; color: #FFF; padding: 2px 0', '1.1.0')

      this.setGoogleFontLink()

      this.$store.commit('RESET')

      this.$store.commit('SET_WIDGET_ELEMENT', document.querySelector('harmo-widget'))

      let widgetUUID = this.$store.state.widgetElement?.getAttribute('uuid')
      let contact = this.$store.state.widgetElement?.getAttribute('client')
      const preview = this.$store.state.widgetElement?.getAttribute('preview')

      if (!widgetUUID) {
        if (process.env.NODE_ENV === 'development') {
          widgetUUID = 'fd3bb985-669e-4509-936e-dbee7315cc55'
        } else {
          throw new Error('Por favor, adicione o uuid do widget.')
        }
      }

      if (!contact) {
        if (process.env.NODE_ENV === 'development') {
          contact = JSON.stringify({
            name: 'Nome do cliente',
            email: `${btoa(Math.random().toString()).substr(10, 5)}@exemplo.com`,
            variables: {
              cidade: 'Nome da cidade',
              estado: 'Nome do estado'
            }
          })
        } else {
          throw new Error('Por favor, adicione os dados do cliente pelo atributo client. O objeto deve ser um JSON!')
        }
      }

      this.$store.commit('SET_WIDGET_UUID', widgetUUID)
      this.$store.commit('SET_CONTACT', JSON.parse(contact as string))
      if (preview) this.$store.commit('SET_IS_PREVIEW', true)

      this.$store.dispatch('getData')
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('%c HARMO Widget ', 'color: red', error.message)
      }
    }
  },
  methods: {
    setAttributes (el: HTMLElement, attrs: { name: string; value: string; }[]) {
      attrs.forEach(attr => {
        el.setAttribute(attr.name, attr.value)
      })
    },
    setGoogleFontLink () {
      const link = document.createElement('link')

      this.setAttributes(link, [
        { name: 'href', value: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap' },
        { name: 'rel', value: 'stylesheet' },
        { name: 'type', value: 'text/css' }
      ])

      document.head.appendChild(link)
    },
    async handleQuestionInput (answer: Answer) {
      if (
        (answer.question.mandatory && answer.answer.length === 0) ||
        (answer.question.mandatory && answer.question.type === 'text' && answer.question.config?.mask && !answer.isValid)
      ) {
        this.$store.commit('ADD_ANSWER', answer)
        return
      }

      if (this.survey.quarantine.quarantineType === 'question' && this.survey.quarantine.questionUUID === answer.question.uuid) {
        await this.$store.dispatch('verifyQuestionQuarantine', { answer })
      }

      this.$store.dispatch('saveAnswer', { answer })
    },
    saveEmptyAnswer () {
      this.$store.dispatch('saveAnswer', {
        answer: {
          question: this.question,
          answer: []
        }
      })
    },
    processQuestion (type: 'next' | 'submit') {
      const questionAnswer = this.$store.state.answers.find((answer: Answer) => answer.question.uuid === this.question.uuid)

      if (this.question.mandatory && (!questionAnswer || questionAnswer.answer.length === 0)) {
        this.$store.commit('SET_QUESTIONS_WITH_ERROR', [this.question.uuid])
        return
      }

      if (this.question.mandatory && this.question.type === 'text' && this.question.config?.mask && !questionAnswer.isValid) {
        this.$store.commit('SET_QUESTIONS_WITH_ERROR', [this.question.uuid])
        return
      }

      if (this.question.type === 'checkbox' && !this.question.mandatory && !questionAnswer) {
        this.saveEmptyAnswer()
      }

      if (this.$store.state.loading.saveAnswer) return

      if (type === 'next') {
        this.$store.commit('NEXT_QUESTION')
      } else if (type === 'submit') {
        this.$store.dispatch('setStatus', { status: 'conclude' })
        this.$store.commit('SET_STATUS', 'CONCLUDED')
      }
    }
  },
  computed: {
    survey (): Survey {
      return this.$store.state.survey
    },
    widget (): Widget {
      return this.$store.state.widget
    },
    status (): Status {
      return this.$store.state.status
    },
    questions (): Question[] {
      return this.$store.getters.questions
    },
    question (): Question {
      return this.$store.getters.questions[this.$store.state.step]
    },
    color (): string {
      return this.survey.customization.primaryColor || '#8700FF'
    },
    surveyWidth (): number {
      if (this.windowWidth >= 640) {
        return this.widget.customize.layoutSize === 'normal' ? 640 : 400
      }

      return this.windowWidth
    },
    widgetStyle (): string {
      let sizeStyle = 'width: 100%;'
      let positionStyle = 'left: 0; right: 0; margin: auto;'

      if (this.windowWidth >= 640) {
        sizeStyle = `width: ${this.widget.customize.layoutSize === 'normal' ? '640px' : '400px'};`

        switch (this.widget.customize.position) {
          case 'left': positionStyle = 'left: 10%;'; break
          case 'center': positionStyle = 'left: 0; right: 0; margin: auto;'; break
          case 'right': positionStyle = 'right: 10%;'
        }
      }

      return sizeStyle + positionStyle
    }
  },
  watch: {
    '$store.state.status' (value) {
      if (process.env.NODE_ENV === 'production') {
        switch (value) {
          case 'OPENED': this.$store.state.widgetElement.dispatchEvent(new CustomEvent('onOpen')); break
          case 'ABANDONED': this.$store.state.widgetElement.dispatchEvent(new CustomEvent('onAbandon')); break
          case 'CONCLUDED': this.$store.state.widgetElement.dispatchEvent(new CustomEvent('onConclude')); break
          case 'CLOSED': this.$store.state.widgetElement.dispatchEvent(new CustomEvent('onClose'))
        }
      }
    }
  }
})
