
import Vue from 'vue'
import Btn from '@/components/Btn.vue'
import { Survey, Answer, ThanksMessage, ThanksMessageEngine, ThanksMessageSource } from '@/types/index'
import { intersection } from 'lodash'

export default Vue.extend({
  components: {
    Btn
  },
  data () {
    return {
      defaultMessage: '<h2>Obrigado pelo seu feedback :)</h2>'
    }
  },
  mounted () {
    this.$store.dispatch('getAvailableThanksMessageEngine')
  },
  methods: {
    reviewButtonColor (source: ThanksMessageSource): string {
      switch (source.name) {
        case 'Google Meu Negócio': return 'google'
        default: return 'google'
      }
    },
    reviewButtonImgSrc (source: ThanksMessageSource) {
      switch (source.name) {
        case 'Google Meu Negócio': return require('@/assets/sources/google-white.png')
        default: return require('@/assets/sources/google-white.png')
      }
    },
    sourceIsAvailable (source: ThanksMessageSource) {
      return this.$store.state.availableThanksMessageEngine.find((availableEngine: ThanksMessageEngine) => availableEngine.engine === source.id)
    },
    async sendToReviewPage (source: ThanksMessageSource) {
      try {
        const engineFound = this.$store.state.availableThanksMessageEngine.find((availableEngine: ThanksMessageEngine) => availableEngine.engine === source.id)

        if (!engineFound) return

        const reviewTab = window.open('about:blank')

        const thanksMessageReviewLink = await this.$store.dispatch('getThanksMessageReviewLink', { engineId: engineFound.engine, credentials: engineFound.pageId })

        if (reviewTab) reviewTab.location = thanksMessageReviewLink
      } catch (error) {
        console.error(error)
      }
    }
  },
  computed: {
    survey (): Survey {
      return this.$store.state.survey
    },
    thanksMessage (): ThanksMessage {
      return this.$store.state.survey.thanksMessage
    },
    questionAnswerMatchesThanksMessageCondition (): boolean {
      const answerFound = this.$store.state.answers.find((answer: Answer) => answer.question.uuid === this.thanksMessage.config.questionUUID) as Answer | undefined

      if (answerFound) {
        const answer = Array.isArray(answerFound.answer) ? answerFound.answer : [answerFound.answer]

        if (intersection(answer, this.thanksMessage.config.conditions).length > 0) {
          return true
        }
      }

      return false
    },
    message (): string {
      if (this.thanksMessage.type === 'conditional') {
        if (this.questionAnswerMatchesThanksMessageCondition) {
          return this.thanksMessage.mainMessage || this.defaultMessage
        }

        return this.thanksMessage.fallbackMessage || this.defaultMessage
      }

      if (this.thanksMessage.type === 'review') {
        if (this.questionAnswerMatchesThanksMessageCondition && this.$store.state.availableThanksMessageEngine.length > 0) {
          return this.thanksMessage.mainMessage || this.defaultMessage
        }

        return this.thanksMessage.fallbackMessage || this.defaultMessage
      }

      return this.thanksMessage.mainMessage || this.defaultMessage
    }
  }
})
